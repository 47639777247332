import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDoneAll } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Тренажерний зал Power club
			</title>
			<meta name={"description"} content={"Простір, де піт і сила сходяться, щоб виліпити найкращу версію вас."} />
			<meta property={"og:title"} content={"Головна | Тренажерний зал Power club"} />
			<meta property={"og:description"} content={"Простір, де піт і сила сходяться, щоб виліпити найкращу версію вас."} />
			<meta property={"og:image"} content={"https://kitelsy.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://kitelsy.com/img/icon 1.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kitelsy.com/img/icon 1.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kitelsy.com/img/icon 1.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kitelsy.com/img/icon 1.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kitelsy.com/img/icon 1.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kitelsy.com/img/icon 1.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0 210px" sm-padding="40px 0 40px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#000000 url(https://kitelsy.com/img/1.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Ласкаво просимо до Power club
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Power club спортзал
				</Text>
				<Text font="--base">
				Простір, де піт і сила сходяться, щоб виліпити найкращу версію вас. Power club Gym - це ваш місцевий притулок для фітнесу, що пропонує поєднання традиційних тренувань із сучасним підходом до оздоровлення.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--dark"
					font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					У Power club ми надаємо комплексний фітнес-досвід, розроблений для всіх рівнів, від початківців до спортсменів. Наша місія - створити середовище, яке мотивує, навчає та підтримує вас під час кожного повторення та кожної пробіжки.
				</Text>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Чому варто обрати Power club?
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Різноманітні варіанти тренувань
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Різноманітність обладнання та класів, щоб ваші тренування були свіжими та цікавими.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Дух спільноти
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Фітнес-спільнота, яка підтримує та надихає.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Ніяких прихованих платежів
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Прозоре ціноутворення, без несподіваних платежів. Ваша фізична форма - наш пріоритет, а не перепродажі.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Зосередьтеся на здоров'ї
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Ми вважаємо, що фітнес - це стиль життя, і наш тренажерний зал сприяє збалансованому підходу до здоров'я.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://kitelsy.com/img/0.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					max-height="380px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Тренажерний зал Power club - де кожне тренування - це перемога

				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-dark"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Зв'яжіться з нами
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});